import Vue from 'vue'
import VueRouter from 'vue-router'
import { languages } from '../i18n'
import store from '@/store'

Vue.use(VueRouter)

function lazyLoad(view){
   return() => import(`@/components/${view}.vue`)
}

const routes = [
   {
      path: '/',
      name: 'root',
      beforeEnter(to, from, next) {
        next(store.state.locale)
      }
    },
    {
      path: '/:lang',
      component: {
         render: h => h('router-view')
       },
      beforeEnter(to, from, next) {
        let lang = to.params.lang
        if (languages.includes(lang)) {
          if (store.state.locale !== lang) {
            store.dispatch('changeLocale', lang)
          }
          return next()
        }
        return next({ path: store.state.locale })
      },
      beforeUpdate (to, from, next) {
         console.log(to, from, next);
         console.log("beforeRoute");
      },
      children: [
         { path: '/:lang/', component: lazyLoad('Home'), name: 'Home' },
         { path: '/:lang/saint-ouen', component: lazyLoad('SaintOuen'), name : 'SaintOuen' },
         { path: '/:lang/traite-rhapsody', component: lazyLoad('TraiteRhapsody'), name : 'TraiteRhapsody' },
         { path: '/:lang/composition-inclusive', component: lazyLoad('CompositionInclusive'), name : 'CompositionInclusive' },
         { path: '/:lang/contact', component: lazyLoad('Contact'), name : 'Contact' },
         { path: '/:lang/telechargement', component: lazyLoad('Telechargement'), name : 'Telechargement' },
         { path: '/:lang/mentions-legales', component: lazyLoad('MentionsLegales'), name : 'MentionsLegales' },
         { path: "/:lang/:pathMatch(.*)*", component: lazyLoad('PageNotFound') }
      ]
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
   scrollBehavior: (to) => {
     /** GESTION ANCRAGE */
     let comboSect = (hash)=>{
        //Check if components is ready for anchor
         let compReady = setInterval(() => {
            if (document.querySelector(".fp-slidesNav")) {
               if (!document.querySelector("a[href='#saintOuen-5']").classList.contains('active')) {                     
                  document.querySelector("a[href='#saintOuen-5']").click()
                  setTimeout(() => {
                     document.querySelector(hash).scrollIntoView({ behavior: 'instant', block: 'start'});
                  }, 1500);
               } else {
                  document.querySelector(hash).scrollIntoView({ behavior: 'instant', block: 'start'});
               } 
               clearInterval(compReady);
            }
         }, 500);
         
      }
      function anchor(){
         var isHash = to.hash
         //***************** */
         //Anchor for mobile
         //***************** */
         if(screen.width < 1025){
            if(isHash != 0){
               setTimeout(() => {
                  document.querySelector(isHash).scrollIntoView({ behavior: 'smooth', block: 'start'});
               }, 1500);
            }
            else {
               setTimeout(() => {
                  document.querySelector("#first-page").scrollIntoView({ behavior: 'smooth', block: 'start'});
               }, 500);
            }
         //***************** */
         //Anchor for desktop
         //***************** */
         }else{
            //Set asynchrone timeOut onload page for fix-switch anchor
            let compReady = setInterval(() => {
               if (document.querySelector(".fp-slidesNav")) {
                  if(window.location.pathname == "/fr/saint-ouen" || window.location.pathname == "/en/saint-ouen"){
                     switch (to.hash) {
                        case "":
                              document.querySelector("a[href='#page1']").click()
                           break;
         
                        case "#suivre-ligne":
                              document.querySelector("a[href='#suivre-ligne']").click()
                           break;
         
                        case "#mobilite-douce":
                              comboSect(isHash); 
                           break;
         
                        case "#saint-ouen-vie":    
                              comboSect(isHash);  
                           break;
                        
                        case "#ca-bouge":
                              document.querySelector("a[href='#ca-bouge']").click()
                           break;
         
                        case "#envole-tertiaire":
                              document.querySelector("a[href='#envole-tertiaire']").click()
                           break;
                        default:
                           break;
                     }       
                  }else if(window.location.pathname == "/fr/composition-inclusive" || window.location.pathname == "/en/composition-inclusive"){
                     switch (to.hash) {
                        case "":
                              document.querySelector("a[href='#section-first']").click()
                           break;
         
                        case "#esquisse-projet":
                              document.querySelector("a[href='#esquisse-projet']").click()
                           break;
         
                        case "#espace-rassembleurs":
                              document.querySelector("a[href='#espace-rassembleurs']").click()
                           break;
         
                        case "#pollinisateur":
                              document.querySelector("a[href='#pollinisateur']").click()
                           break;
                        
                        case "#roof-top":
                              document.querySelector("a[href='#roof-top']").click()
                           break;
         
                        default:
                           break;
                     }       
                  }
                  clearInterval(compReady);
               }
            }, 500);
         }
      }
      anchor()
   }
})

export default router