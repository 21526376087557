<template>
  <div id="app">
      <Loader/>
      <Header/>
      <router-view :key="$route.path"></router-view>
      <Bandeau/>
      <Footer/>
      
    </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Header: () => import('@/components/Header.vue') ,
    Bandeau: () => import('@/components/Bandeau.vue'),
    Footer: () => import('@/components/Footer.vue'),
    Loader: () => import('@/components/Loader.vue')
  },
  props: {
    msg: String
  },
  methods:{
      wheelDistance(e) {
         if (!e) {
               e = window.event;
         }
         var w = e.wheelDelta,
               d = e.detail;
         if (d) {
               return -d / 3; // Firefox;
         }

         // IE, Safari, Chrome & other browsers
         return w / 120;
      },

      handleScroll(e) {
         this.wheelDistance(e);
      },
  },
  created(){
        window.addEventListener("DOMMouseScroll", this.handleScroll);
        window.addEventListener("mousewheel", this.handleScroll);
        this.$store.dispatch('changeLocale', this.$store.state.locale)
  },
  destroyed(){
        window.removeEventListener("DOMMouseScroll", this.handleScroll);
        window.removeEventListener("mousewheel", this.handleScroll);
  },
  mounted () {
    smoothscroll.polyfill();
    // setTimeout(function(){
    //   document.querySelector('.custom-pointer').classList.remove('hideAfterLoad');
    //   document.querySelector('#mouseanim').classList.remove('hideAfterLoad');
    // },1000)
  },
  computed: mapState(['locale']),
  watch: {
    locale() {
      this.$router.replace({ params: { lang: this.locale } }).catch(() => {})
    }
  },
}
</script>


<style lang="scss">
  @import '@/assets/scss/main.scss';
</style>
