import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import checkView from 'vue-check-view'
import VueNumber from 'vue-number-animation'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics';
import "fullpage.js/dist/fullpage.css"
import VueFullPage from 'vue-fullpage.js'
import VuePlayer from '@iomariani/vue-player'
import i18n from './i18n'

Vue.component('vue-player', VuePlayer)

Vue.use(VueNumber)
Vue.use(checkView)
Vue.use(VueMeta)
Vue.config.productionTip = false
Vue.config.performance = true
Vue.use(VueFullPage)

Vue.use(VueAnalytics, {
  id: 'G-QJK7R6YPRR',
  router
}),

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,

}).$mount('#app')
